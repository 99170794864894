// @flow
const ICONS = {
  TWITTER: {
    path: 'M25.312 6.375c-0.688 1-1.547 1.891-2.531 2.609 0.016 0.219 0.016 0.438 0.016 0.656 0 6.672-5.078 14.359-14.359 14.359-2.859 0-5.516-0.828-7.75-2.266 0.406 0.047 0.797 0.063 1.219 0.063 2.359 0 4.531-0.797 6.266-2.156-2.219-0.047-4.078-1.5-4.719-3.5 0.313 0.047 0.625 0.078 0.953 0.078 0.453 0 0.906-0.063 1.328-0.172-2.312-0.469-4.047-2.5-4.047-4.953v-0.063c0.672 0.375 1.453 0.609 2.281 0.641-1.359-0.906-2.25-2.453-2.25-4.203 0-0.938 0.25-1.797 0.688-2.547 2.484 3.062 6.219 5.063 10.406 5.281-0.078-0.375-0.125-0.766-0.125-1.156 0-2.781 2.25-5.047 5.047-5.047 1.453 0 2.766 0.609 3.687 1.594 1.141-0.219 2.234-0.641 3.203-1.219-0.375 1.172-1.172 2.156-2.219 2.781 1.016-0.109 2-0.391 2.906-0.781z',
    viewBox: '0 0 26 28'
  },
  FACEBOOK: {
    path: 'M14.984 0.187v4.125h-2.453c-1.922 0-2.281 0.922-2.281 2.25v2.953h4.578l-0.609 4.625h-3.969v11.859h-4.781v-11.859h-3.984v-4.625h3.984v-3.406c0-3.953 2.422-6.109 5.953-6.109 1.687 0 3.141 0.125 3.563 0.187z',
    viewBox: '0 0 16 28'
  },
  TELEGRAM: {
    path: 'M27.563 0.172c0.328 0.234 0.484 0.609 0.422 1l-4 24c-0.047 0.297-0.234 0.547-0.5 0.703-0.141 0.078-0.313 0.125-0.484 0.125-0.125 0-0.25-0.031-0.375-0.078l-7.078-2.891-3.781 4.609c-0.187 0.234-0.469 0.359-0.766 0.359-0.109 0-0.234-0.016-0.344-0.063-0.391-0.141-0.656-0.516-0.656-0.938v-5.453l13.5-16.547-16.703 14.453-6.172-2.531c-0.359-0.141-0.594-0.469-0.625-0.859-0.016-0.375 0.172-0.734 0.5-0.922l26-15c0.156-0.094 0.328-0.141 0.5-0.141 0.203 0 0.406 0.063 0.562 0.172z',
    viewBox: '0 0 28 28'
  },
  VKONTAKTE: {
    path: 'M29.953 8.125c0.234 0.641-0.5 2.141-2.344 4.594-3.031 4.031-3.359 3.656-0.859 5.984 2.406 2.234 2.906 3.313 2.984 3.453 0 0 1 1.75-1.109 1.766l-4 0.063c-0.859 0.172-2-0.609-2-0.609-1.5-1.031-2.906-3.703-4-3.359 0 0-1.125 0.359-1.094 2.766 0.016 0.516-0.234 0.797-0.234 0.797s-0.281 0.297-0.828 0.344h-1.797c-3.953 0.25-7.438-3.391-7.438-3.391s-3.813-3.938-7.156-11.797c-0.219-0.516 0.016-0.766 0.016-0.766s0.234-0.297 0.891-0.297l4.281-0.031c0.406 0.063 0.688 0.281 0.688 0.281s0.25 0.172 0.375 0.5c0.703 1.75 1.609 3.344 1.609 3.344 1.563 3.219 2.625 3.766 3.234 3.437 0 0 0.797-0.484 0.625-4.375-0.063-1.406-0.453-2.047-0.453-2.047-0.359-0.484-1.031-0.625-1.328-0.672-0.234-0.031 0.156-0.594 0.672-0.844 0.766-0.375 2.125-0.391 3.734-0.375 1.266 0.016 1.625 0.094 2.109 0.203 1.484 0.359 0.984 1.734 0.984 5.047 0 1.062-0.203 2.547 0.562 3.031 0.328 0.219 1.141 0.031 3.141-3.375 0 0 0.938-1.625 1.672-3.516 0.125-0.344 0.391-0.484 0.391-0.484s0.25-0.141 0.594-0.094l4.5-0.031c1.359-0.172 1.578 0.453 1.578 0.453z',
    viewBox: '0 0 31 28'
  },
  GITHUB: {
    path: 'M10 19c0 1.141-0.594 3-2 3s-2-1.859-2-3 0.594-3 2-3 2 1.859 2 3zM20 19c0 1.141-0.594 3-2 3s-2-1.859-2-3 0.594-3 2-3 2 1.859 2 3zM22.5 19c0-2.391-1.453-4.5-4-4.5-1.031 0-2.016 0.187-3.047 0.328-0.812 0.125-1.625 0.172-2.453 0.172s-1.641-0.047-2.453-0.172c-1.016-0.141-2.016-0.328-3.047-0.328-2.547 0-4 2.109-4 4.5 0 4.781 4.375 5.516 8.188 5.516h2.625c3.813 0 8.188-0.734 8.188-5.516zM26 16.25c0 1.734-0.172 3.578-0.953 5.172-2.063 4.172-7.734 4.578-11.797 4.578-4.125 0-10.141-0.359-12.281-4.578-0.797-1.578-0.969-3.437-0.969-5.172 0-2.281 0.625-4.438 2.125-6.188-0.281-0.859-0.422-1.766-0.422-2.656 0-1.172 0.266-2.344 0.797-3.406 2.469 0 4.047 1.078 5.922 2.547 1.578-0.375 3.203-0.547 4.828-0.547 1.469 0 2.953 0.156 4.375 0.5 1.859-1.453 3.437-2.5 5.875-2.5 0.531 1.062 0.797 2.234 0.797 3.406 0 0.891-0.141 1.781-0.422 2.625 1.5 1.766 2.125 3.938 2.125 6.219z',
    viewBox: '0 0 26 28'
  },
  EMAIL: {
    path: 'M26 23.5v-12c-0.328 0.375-0.688 0.719-1.078 1.031-2.234 1.719-4.484 3.469-6.656 5.281-1.172 0.984-2.625 2.188-4.25 2.188h-0.031c-1.625 0-3.078-1.203-4.25-2.188-2.172-1.813-4.422-3.563-6.656-5.281-0.391-0.313-0.75-0.656-1.078-1.031v12c0 0.266 0.234 0.5 0.5 0.5h23c0.266 0 0.5-0.234 0.5-0.5zM26 7.078c0-0.391 0.094-1.078-0.5-1.078h-23c-0.266 0-0.5 0.234-0.5 0.5 0 1.781 0.891 3.328 2.297 4.438 2.094 1.641 4.188 3.297 6.266 4.953 0.828 0.672 2.328 2.109 3.422 2.109h0.031c1.094 0 2.594-1.437 3.422-2.109 2.078-1.656 4.172-3.313 6.266-4.953 1.016-0.797 2.297-2.531 2.297-3.859zM28 6.5v17c0 1.375-1.125 2.5-2.5 2.5h-23c-1.375 0-2.5-1.125-2.5-2.5v-17c0-1.375 1.125-2.5 2.5-2.5h23c1.375 0 2.5 1.125 2.5 2.5z',
    viewBox: '0 0 28 28'
  },
  RSS: {
    path: 'M6 21c0 1.656-1.344 3-3 3s-3-1.344-3-3 1.344-3 3-3 3 1.344 3 3zM14 22.922c0.016 0.281-0.078 0.547-0.266 0.75-0.187 0.219-0.453 0.328-0.734 0.328h-2.109c-0.516 0-0.938-0.391-0.984-0.906-0.453-4.766-4.234-8.547-9-9-0.516-0.047-0.906-0.469-0.906-0.984v-2.109c0-0.281 0.109-0.547 0.328-0.734 0.172-0.172 0.422-0.266 0.672-0.266h0.078c3.328 0.266 6.469 1.719 8.828 4.094 2.375 2.359 3.828 5.5 4.094 8.828zM22 22.953c0.016 0.266-0.078 0.531-0.281 0.734-0.187 0.203-0.438 0.313-0.719 0.313h-2.234c-0.531 0-0.969-0.406-1-0.938-0.516-9.078-7.75-16.312-16.828-16.844-0.531-0.031-0.938-0.469-0.938-0.984v-2.234c0-0.281 0.109-0.531 0.313-0.719 0.187-0.187 0.438-0.281 0.688-0.281h0.047c5.469 0.281 10.609 2.578 14.484 6.469 3.891 3.875 6.188 9.016 6.469 14.484z',
    viewBox: '0 0 22 28'
  },
  GITLAB: {
    path: `M 0.00,-123.00
    C 0.00,-123.00 -124.00,-123.00 -124.00,-123.00
      -124.00,-123.00 0.00,-123.00 0.00,-123.00 Z
    M 462.20,-123.00
    C 462.20,-123.00 338.20,-123.00 338.20,-123.00
      338.20,-123.00 462.20,-123.00 462.20,-123.00 Z
    M -124.00,-122.50
    C -124.00,-122.50 462.00,-122.50 462.00,-122.50
      462.00,-122.50 -124.00,-122.50 -124.00,-122.50
      -124.00,-122.50 -124.00,-122.50 -124.00,-122.50 Z
    M 337.51,175.36
    C 337.51,175.36 319.47,119.77 319.47,119.77
      319.47,119.79 319.48,119.82 319.48,119.84
      319.48,119.82 319.47,119.79 319.46,119.76
      319.46,119.76 319.46,119.75 319.46,119.75
      319.46,119.75 319.46,119.75 319.46,119.75
      319.46,119.75 283.63,9.44 283.63,9.44
      281.67,3.63 276.28,-0.27 270.10,-0.22
      263.89,-0.19 258.65,3.63 256.73,9.53
      256.73,9.53 222.72,114.23 222.72,114.23
      222.72,114.23 115.41,114.23 115.41,114.23
      115.41,114.23 81.33,9.52 81.33,9.52
      79.42,3.63 74.17,-0.19 67.97,-0.22
      67.95,-0.22 67.92,-0.22 67.90,-0.22
      61.79,-0.22 56.39,3.65 54.41,9.53
      54.41,9.53 18.68,119.74 18.68,119.74
      18.68,119.74 18.67,119.75 18.67,119.75
      18.67,119.75 18.67,119.75 18.67,119.76
      18.66,119.78 18.66,119.81 18.65,119.84
      18.66,119.81 18.66,119.79 18.67,119.76
      18.67,119.76 0.55,175.35 0.55,175.35
      -2.17,183.75 0.78,192.87 7.91,198.05
      7.91,198.05 164.33,311.70 164.33,311.70
      164.35,311.72 164.38,311.73 164.40,311.74
      164.47,311.79 164.53,311.84 164.60,311.89
      164.54,311.84 164.48,311.80 164.41,311.75
      164.41,311.75 164.41,311.75 164.41,311.75
      164.42,311.76 164.43,311.76 164.44,311.77
      164.45,311.78 164.46,311.78 164.47,311.79
      164.47,311.79 164.47,311.79 164.48,311.79
      164.72,311.96 164.97,312.11 165.23,312.25
      165.26,312.27 165.30,312.30 165.33,312.32
      165.33,312.32 165.33,312.32 165.33,312.32
      165.36,312.33 165.38,312.34 165.40,312.35
      165.42,312.36 165.43,312.37 165.45,312.38
      165.46,312.38 165.47,312.38 165.49,312.39
      165.52,312.40 165.55,312.41 165.58,312.43
      165.71,312.49 165.86,312.55 166.00,312.60
      166.13,312.66 166.25,312.72 166.39,312.77
      166.39,312.77 166.40,312.77 166.40,312.77
      166.43,312.78 166.45,312.79 166.47,312.80
      166.50,312.81 166.52,312.82 166.55,312.83
      166.56,312.83 166.57,312.84 166.59,312.84
      166.62,312.85 166.66,312.85 166.69,312.86
      166.71,312.87 166.72,312.87 166.74,312.87
      166.92,312.93 167.12,312.97 167.31,313.02
      167.39,313.03 167.46,313.06 167.54,313.07
      167.55,313.07 167.56,313.08 167.57,313.08
      167.60,313.08 167.62,313.09 167.65,313.10
      167.69,313.10 167.73,313.12 167.77,313.12
      167.80,313.13 167.84,313.13 167.87,313.13
      167.87,313.13 167.88,313.13 167.89,313.13
      167.89,313.13 167.89,313.13 167.89,313.13
      168.26,313.18 168.63,313.22 169.01,313.22
      169.02,313.22 169.02,313.22 169.02,313.22
      169.02,313.22 169.02,313.22 169.02,313.22
      169.02,313.22 169.02,313.22 169.02,313.22
      169.02,313.22 169.02,313.22 169.02,313.22
      169.03,313.22 169.03,313.22 169.03,313.22
      169.41,313.22 169.78,313.18 170.15,313.13
      170.15,313.13 170.16,313.13 170.16,313.13
      170.16,313.13 170.17,313.13 170.18,313.13
      170.21,313.13 170.24,313.13 170.28,313.12
      170.32,313.12 170.36,313.10 170.40,313.10
      170.43,313.09 170.45,313.08 170.47,313.08
      170.48,313.08 170.49,313.07 170.51,313.07
      170.58,313.06 170.66,313.03 170.74,313.01
      170.93,312.97 171.12,312.93 171.31,312.88
      171.32,312.87 171.33,312.87 171.35,312.87
      171.38,312.86 171.42,312.85 171.46,312.84
      171.47,312.84 171.48,312.83 171.49,312.83
      171.52,312.82 171.55,312.81 171.58,312.80
      171.60,312.79 171.62,312.78 171.64,312.77
      171.65,312.77 171.65,312.77 171.66,312.77
      171.80,312.72 171.93,312.65 172.07,312.59
      172.20,312.54 172.33,312.49 172.46,312.43
      172.49,312.42 172.52,312.41 172.55,312.39
      172.57,312.39 172.58,312.38 172.59,312.38
      172.61,312.37 172.63,312.36 172.64,312.36
      172.67,312.34 172.69,312.33 172.71,312.32
      172.71,312.32 172.71,312.32 172.71,312.32
      172.75,312.30 172.78,312.27 172.82,312.25
      173.08,312.11 173.33,311.96 173.57,311.79
      173.59,311.78 173.61,311.77 173.63,311.75
      173.63,311.75 173.64,311.75 173.64,311.74
      173.67,311.73 173.69,311.72 173.72,311.70
      173.72,311.70 330.16,198.05 330.16,198.05
      337.28,192.87 340.24,183.75 337.51,175.36 Z
    M 270.19,19.77
    C 270.19,19.77 300.87,114.23 300.87,114.23
      300.87,114.23 239.51,114.23 239.51,114.23
      239.51,114.23 270.19,19.77 270.19,19.77 Z
    M 295.50,130.20
    C 295.50,130.20 282.98,146.24 282.98,146.24
      282.98,146.24 190.65,264.55 190.65,264.55
      190.65,264.55 234.33,130.20 234.33,130.20
      234.33,130.20 295.50,130.20 295.50,130.20 Z
    M 161.43,307.71
    C 161.43,307.71 161.43,307.71 161.43,307.71
      161.44,307.73 161.45,307.75 161.46,307.78
      161.45,307.75 161.44,307.73 161.43,307.71 Z
    M 147.42,264.56
    C 147.42,264.56 42.62,130.20 42.62,130.20
      42.62,130.20 42.62,130.20 42.62,130.20
      42.62,130.20 103.80,130.20 103.80,130.20
      103.80,130.20 147.42,264.56 147.42,264.56 Z
    M 67.88,19.77
    C 67.88,19.77 98.61,114.23 98.61,114.23
      98.61,114.23 37.25,114.23 37.25,114.23
      37.25,114.23 67.88,19.77 67.88,19.77 Z
    M 17.30,185.13
    C 15.79,184.03 15.16,182.08 15.74,180.29
      15.74,180.29 29.21,138.97 29.21,138.97
      29.21,138.97 127.88,265.47 127.88,265.47
      127.88,265.47 17.30,185.13 17.30,185.13 Z
    M 164.05,311.49
    C 164.02,311.46 163.99,311.44 163.96,311.41
      163.96,311.41 163.95,311.40 163.94,311.39
      163.88,311.34 163.82,311.29 163.77,311.24
      163.65,311.14 163.54,311.04 163.43,310.94
      163.44,310.94 163.45,310.95 163.46,310.96
      163.47,310.97 163.49,310.98 163.50,310.99
      163.73,311.21 163.97,311.41 164.22,311.60
      164.22,311.61 164.22,311.61 164.23,311.61
      164.24,311.62 164.25,311.63 164.26,311.64
      164.19,311.59 164.12,311.54 164.05,311.49 Z
    M 169.03,279.39
    C 169.03,279.39 143.36,200.33 143.36,200.33
      143.36,200.33 120.60,130.20 120.60,130.20
      120.60,130.20 217.53,130.20 217.53,130.20
      217.53,130.20 169.03,279.39 169.03,279.39 Z
    M 174.28,311.24
    C 174.22,311.29 174.16,311.34 174.10,311.39
      174.09,311.40 174.09,311.41 174.08,311.41
      174.05,311.44 174.02,311.47 173.99,311.49
      173.93,311.54 173.85,311.59 173.78,311.64
      173.80,311.63 173.81,311.62 173.82,311.61
      173.82,311.61 173.82,311.61 173.82,311.61
      174.08,311.42 174.32,311.21 174.54,311.00
      174.55,310.99 174.56,310.98 174.57,310.97
      174.58,310.96 174.60,310.95 174.61,310.94
      174.50,311.04 174.39,311.14 174.28,311.24 Z
    M 320.77,185.13
    C 320.77,185.13 210.21,265.45 210.21,265.45
      210.21,265.45 308.91,138.98 308.91,138.98
      308.91,138.98 322.32,180.29 322.32,180.29
      322.91,182.08 322.28,184.03 320.77,185.13 Z`,
      viewBox: "0 0 338 313"
  },
};

export default ICONS;
